import React from "react"
import ProductsLayout from "../../components/productsLayout"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import ImageGallery from "react-image-gallery"
import Img1 from "../../images/profilisani.jpg"
import PresekImg from "../../images/presek.png"
import Head from "../../components/head"

const profilisaniLimovi = () => {
  const images = [
    {
      original: `${Img1}`,
      thumbnail: `${Img1}`,
    },
  ]
  return (
    <Layout>
      <Head title="Profilisani limovi" />
      <div className="products-layout">
        <section className="hero is-link">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h1 className="title">Profilisani limovi</h1>
                </div>
                <div className="column">
                  <nav className="breadcrumb is-right" aria-label="breadcrumbs">
                    <ul>
                      <li>
                        <Link to="/proizvodi">Proizvodi</Link>
                      </li>
                      <li class="is-active">
                        <a href="#" aria-current="page">
                          Profilisani limovi
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ProductsLayout>
          <div className="columns products-list">
            <div className="column">
              <ImageGallery
                additionalClass="box"
                infinite={false}
                showNav={false}
                thumbnailPosition="bottom"
                lazyLoad={true}
                showThumbnails={false}
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
                originalClass="main-img"
              />
            </div>
            <div className="column">
              <p>
                <b>Trapezno profilisani lim</b> namenjen je za spoljašnje
                oblaganje krovova i zidova svih vrsta građevinskih objekata i
                ugrađuje se jednostruka pokrivka iii kao sendvič panel gde se
                kao izolacija koristi mineralna vuna.
              </p>
              <br />
              <div className="columns is-multiline">
                <div className="column is-full">
                  <p>
                    <b>Hemijski sastav:</b>
                    <br />
                    Prema standardu za tehničke uslove i isporuku JUS C.B4.025
                    za pocinkovane limove debljine od 0,5mm do 0,7mm i JUS
                    C4.020 za limove i trake od aluminnijuma debljine od 0,5mm
                    do 0,8mm.
                  </p>
                </div>
                <div className="column is-full">
                  <b>Asortiman:</b>
                  <p>
                    Trapezno profilisani limovi isporučuju se prema definisanim
                    dimenzijama u obliku:
                  </p>
                </div>
                <div className="column">
                  <ul>
                    <li>
                      <b>Table</b> TR 37/200
                    </li>
                    <li>visina koraka: 37</li> <li>korak profila: 200mm</li>
                    <li>radna širina: od 1000 do 1250mm</li>
                    <li>korisna širina: od 805 do 1000mm</li>
                    <li>dužina: do 12000mm</li>
                  </ul>
                </div>
                <div className="column">
                  <p>
                    Trapezno profilisani limovi izrađuju se u zelenoj, crvenoj,
                    silver, plavoj, beloj i braon broji, kao i u mnogim drugim.
                    Dužine su po zahtevu kupca.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="columns is-multiline  products-list">
            <div className="column is-full">
              <ul>
                <li>
                  <p>
                    <b>Izgled preseka profila</b>
                  </p>
                  <li>
                    <img src={PresekImg} style={{ width: "100%" }} alt="" />
                  </li>
                </li>
              </ul>
            </div>
            <div className="column">
              <ul>
                <li>
                  <p>
                    <b>Dozvoljena odstupanja</b>
                  </p>
                </li>
                <li>
                  <p>
                    Propisuje standard za toleranciju oblika i mera JUS EN 10131
                    za hladnovaljane pljosnate proizvode bez prevlake od
                    niskougljeničnog čelika i čelika sa visokim naponom tečenja
                    za hladno oblikovanje i JUS C.B4.081 za pocinkovanje limove.
                  </p>
                </li>
                <li>
                  <p>
                    Propisuje standard za oblik i mere, za pocinkovane limove i
                    za hladnovaljane limove od aluminijuma.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </ProductsLayout>
      </div>
    </Layout>
  )
}

export default profilisaniLimovi
